import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  isLoading: boolean = false;
  constructor(private readonly loaderService: CommonService) {
    this.loaderService.isLoading$.subscribe((loading: boolean) => {
      console.log('inside loader service');
      this.isLoading = loading;
    });
  }
}
